import {h} from 'preact';
import {Router} from 'preact-router';
import {Provider} from '@preact/prerender-data-provider';
import Home from '../routes/home';
import Header from './Header/Header';
import Footer from "./Footer/Footer";
// import Cases from "../routes/projects";
// import About from "../routes/team";
// import Blog from "../routes/blog";
// import Services from "../routes/services";
import Contacts from "../routes/contacts";
// import ReputationDecisions from "../routes/reputation_decisions";
// import StartUp from "../routes/start_ups";
// import Optimization from "../routes/optimization";
// import Loyalty from "../routes/loyalty";
// import Sales from "../routes/sales";
// import Competitiveness from "../routes/competitiveness";
// import WebDev from "../routes/web-develop";
// import MobileDev from "../routes/mobile-develop";
// import Outstaff from "../routes/outstaff";
// import TechnicalSupport from "../routes/technical_support";
// import NoCode from "../routes/no-code";
// import BoxSolution from "../routes/boxed_solutions";
// import Design from "../routes/design";
import Success from "../routes/success";
import Info from "../routes/info";
import BasicInfo from "../routes/info/main-info";
// import FinPro from "../routes/finpro";
// import UkiePage from "../routes/ukie";
// import Continental from "../routes/contenintal";
// import RealEstate from "../routes/real_estate";
// import FilaraCosmo from "../routes/filara";
// import GenPortfolio from "../routes/gen_portfolio";
import Documentation from "../routes/documentation/documentation";
import DocumentationPdf from "../routes/documentationPdf/documentationPdf";

import Privacy from "../routes/privacy";
import NotFound from "../routes/404/404";
import Obrazovanie from "../routes/info/obrazovanie";
import Samoobsledovanie from "../routes/info/samoobsledovanie";
import Osnashenie from "../routes/info/osnashenie";
import DostupnaiaSreda from "../routes/info/dostupnaia-sreda";
import MejdunarodnoeSotrudnichestvo from "../routes/info/mejdunarodnoe-sotrudnichestvo";
import Stidepndii from "../routes/info/stidepndii";
import Rukovodstvo from "../routes/info/rukovodstvo";
import Vacant from "../routes/info/Vacant";
import Docs from "../routes/info/Docs";
import Structure from "../routes/info/structure";
import PlatnieUslugi from "../routes/info/platnie-uslugi";
import FinHozDeiatelnost from "../routes/info/fin-hoz-deiatelnost";
import Standarts from "../routes/info/Standarts";
import Pitanie from "../routes/info/pitanie";
import Fz152 from "../routes/info/fz-152";


const App = ({changeLanguage, ...props}) => (
    <Provider value={props}>
        <div id="app">


            <Header changeLanguage={changeLanguage}/>
            <Router>
                <Home path="/"/>
                {/* <Cases path="/projects" /> */}
                <Documentation path="/svedenia-ob-obrazovatelnoy-organizazii/obrazovanie"/>
                <DocumentationPdf path="/documentation-pdf"/>

                <Samoobsledovanie path="/otchet-ob-samoobsledovanii"/>
                <Obrazovanie path="/obrazovanie"/>
                <Osnashenie path="/matierial-no-tiekhnichieskoie-obiespiechieniie-i-osnashchiennost-obrazovatielnogho-protsiessa"/>
                <BasicInfo path="/info/basic"/>
                <Info path="/info"/>
                <Contacts path="/contacts"/>
                <DostupnaiaSreda path="/dostupnaia-sreda"/>
                <Fz152 path="/fz-152"/>
                <MejdunarodnoeSotrudnichestvo path="/mejdunarodnoe-sotrudnichestvo"/>
                <Stidepndii path="/stipendii"/>
                <Rukovodstvo path="/rukovodstvo"/>
                <Vacant path="/vacant"/>
                <Docs path="/docs"/>
                <PlatnieUslugi path="/platnie-uslugi"/>
                <FinHozDeiatelnost path="/fin-hoz-deiatelnost"/>
                <Standarts path="/standarts"/>
                {/*<Standarts path="/pitanie"/>*/}
                <Pitanie path="/pitanie"/>
                <Structure path="/structure"/>
                <Success path="/success"/>
                {/* <About path="/team" />
                <Blog path="/blog" />
                <Services path="/services" />
                
                <ReputationDecisions path="/reputation-decisions" />
                <StartUp path="/startups" />
                <Optimization path="/optimization" />
                <Loyalty path="/loyalty" />
                <Sales path="/sales" />
                <Competitiveness path="/competitiveness" />
                <WebDev path="/services/web-development" />
                <MobileDev path="/services/mobile-development" />
                <Outstaff path="/services/outstaff" />
                <TechnicalSupport path="/services/technical-support" />
                <NoCode path="/services/no-code" />
                <BoxSolution path="/services/boxed-solutions" />
                <Design path="/services/design" />
                
                <GenPortfolio path="/g/portfolio/:name" />
                <FinPro path="/projects/crm/fin-pro" />
                <UkiePage path="/projects/web/ukie" />
                <Continental path="/projects/web/continental/" />
                <RealEstate path="/projects/crm/real-estate" />
                <FilaraCosmo path="/projects/mobile/filara" /> */}
                <Privacy path="/privacy"/>
                <NotFound path="/404"/>
            </Router>
            <Footer/>
        </div>
    </Provider>
);

export default App;
