import {h} from 'preact';
import LinksToSocialNetworks from "../../components/LinksToSocialNetworks/LinksToSocialNetworks";
import CasesGrid from "../../components/CasesGrid/CasesGrid";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import styles from "./style.scss"
import ReturnBack from "./ReturnBack";

const Success = () => {
    return (
        <main id="main-container" className={styles.wrapper} main-attr>
            <ReturnBack></ReturnBack>
            <h1>Структура и органы управления образовательной организацией</h1>
            <h2>ООО «ИТК»</h2>

            <div className="section">
                <h3>Органы управления организацией:</h3>
                <p>Высший орган управления: Общее собрание участников ООО «ИТК»</p>

                <h3>Единоличный исполнительный орган:</h3>
                <p>
                    Генеральный директор: Тетеревлев Игорь Николаевич.
                <br/>Органы управления утверждены Уставом Общества с ограниченной ответственностью «ИТК»</p>

                <h3>Место нахождения органа управления:</h3>
                <p>
                    347900, Ростовская область, г Таганрог, Тургеневский пер, д. 11, ком. 1-7
                </p>

                <h3> Контактный телефон:</h3>
            <p>
                +7 (995) 930-10-20</p>

                <h3>Адрес электронной почты:</h3>
            <p>
                it.consult.tag@gmail.com</p>

                {/*<h3>Органы управления:</h3>*/}
                {/*<p><strong>Учредитель (-и)</strong> – высший орган управления<br/>*/}
                {/*    <strong>Тетеревлев Игорь Николаевич</strong></p>*/}
                {/*<p><strong>Генеральный директор</strong> – постоянно действующий единоличный исполнительный орган<br/>*/}
                {/*    <strong>Тетеревлев Игорь Николаевич</strong></p>*/}
            </div>

            <div className="section">
                <h3>Структура ООО «ИТК»</h3>
                {/*<img src="/assets/documents/img.png" alt="" style={{margin: '20px 0', width: '100%'}}/>*/}
                <table className="info-table" border={1}>
                    <tr>
                        <th>Наименование структурного подразделения</th>
                        <th>Руководитель структурного подразделения (должность, Ф.И.О.)</th>
                        <th>Местонахождение, адрес официального сайта, адрес электронной почты</th>
                    </tr>
                    <tr>
                        <td>Администрация</td>
                        <td>Генеральный директор<br/>Тетеревлев Игорь Николаевич</td>
                        <td>347900, Ростовская область, г Таганрог, Тургеневский пер, д. 11, ком. 1-7<br/>it.consult.tag@gmail.com
                        </td>
                    </tr>
                    <tr>
                        <td>Бухгалтерия</td>
                        <td>Главный бухгалтер<br/>Тетеревлев Игорь Николаевич</td>
                        <td>347900, Ростовская область, г Таганрог, Тургеневский пер, д. 11, ком. 1-7<br/>t.consult.tag@gmail.com
                        </td>
                    </tr>
                    <tr>
                        <td>Методический отдел</td>
                        <td>Руководитель отдела<br/>Вакансия</td>
                        <td>347900, Ростовская область, г Таганрог, Тургеневский пер, д. 11, ком. 1-7<br/>it.consult.tag@gmail.com
                        </td>
                    </tr>
                    <tr>
                        <td>Отдел продаж</td>
                        <td>Руководитель отдела<br/>Вакансия</td>
                        <td>347900, Ростовская область, г Таганрог, Тургеневский пер, д. 11, ком. 1-7<br/>it.consult.tag@gmail.com
                        </td>
                    </tr>
                </table>
            </div>
            <h2>Документы</h2>
            <ul className={'pdfLinks'}>
                <li>
                    <a target="_blank" href={'/assets/documents/1_polozenie-bux.pdf'}>Положение о бухгалтерии
                    </a>
                </li>
                <li>
                    <a target="_blank" href={'/assets/documents/norm/2_polozenie-metod.pdf'}>Положение о методическом
                        отделе
                    </a>
                </li>
                <li>
                    <a target="_blank" href={'/assets/documents/norm/3_polozenie-prodaz.pdf'}>Положение об отделе продаж
                    </a>
                </li>

            </ul>

        </main>
    );
};

export default Success;
