import {h} from 'preact';
import LinksToSocialNetworks from "../../components/LinksToSocialNetworks/LinksToSocialNetworks";
import CasesGrid from "../../components/CasesGrid/CasesGrid";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import styles from "./style.scss"
import ReturnBack from "./ReturnBack";

const Success = () => {
  return (
      <main id="main-container" className={styles.wrapper} main-attr>
          <ReturnBack></ReturnBack>

          <h1>Образовательные стандарты</h1>
          <p>
              В ООО «ИТК» не реализуются образовательные программы, к которым предъявляются требования федеральных государственных образовательных стандартов.
          </p>
      </main>
  );
};

export default Success;
