import {h} from 'preact';
import LinksToSocialNetworks from "../../components/LinksToSocialNetworks/LinksToSocialNetworks";
import CasesGrid from "../../components/CasesGrid/CasesGrid";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import styles from "./style.scss"
import ReturnBack from "./ReturnBack";

const Success = () => {
  return (
      <main id="main-container" className={styles.wrapper} main-attr>
          <ReturnBack></ReturnBack>

          <h1>ФЗ-152 о защите персональных данных</h1>
          <h2>Документы</h2>
          <ul className={'pdfLinks'}>
              {/*<li>*/}
              {/*    <a target="_blank" href={'/assets/documents/platnie/1_polozenie-platnye-uslugi.pdf'}>Положение о*/}
              {/*        порядке оказания платных образовательных услуг*/}
              {/*    </a>*/}
              {/*</li>*/}
              <li>
                  <a target="_blank"
                     href={'/assets/documents/152/politika.pdf'}>
                      Политика обработки персональных данных_ИТК
                  </a>
              </li>
              <li>
                  <a target="_blank"
                     href={'/assets/documents/152/polozenie.pdf'}>
                      Положение об обработке персональных данных
                  </a>
              </li>
              <li>
                  <a target="_blank"
                     href={'/assets/documents/152/reglament.pdf'}>
                      Регламент обработки обращении субъектов персональных данных
                  </a>
              </li>
              <li>
                  <a target="_blank"
                     href={'/assets/documents/152/perechen.pdf'}>
                      Перечень процессов обработки персональных данных
                  </a>
              </li>
              <li>
                  <a target="_blank"
                     href={'/assets/documents/152/prikaz.pdf'}>
                      Приказ об организации работ, связанных с обратботкой и обеспечением безопасности персональных данных
                  </a>
              </li>
              {/*<li>*/}
              {/*    <a target="_blank" href={'/assets/documents/platnie/2_prikaz-o-stoimosti.pdf'}>Приказ об утверждении*/}
              {/*        стоимости обучения*/}
              {/*    </a>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*    <a target="_blank" href={'/assets/documents/4_prikaz-o-stoimosti-24.pdf'}>Приказ об утверждении*/}
              {/*        стоимости обучения на 2024 год*/}
              {/*    </a>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*    <a target="_blank" href={'/assets/documents/dogovor.pdf'}>Договор на обучение ООО ИТК*/}
              {/*    </a>*/}
              {/*</li>*/}
          </ul>
      </main>
  );
};

export default Success;
