import {h} from 'preact';
import LinksToSocialNetworks from "../../components/LinksToSocialNetworks/LinksToSocialNetworks";
import CasesGrid from "../../components/CasesGrid/CasesGrid";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import styles from "./style.scss"
import ReturnBack from "./ReturnBack";

const Success = () => {
    return (
        <main id="main-container" className={styles.wrapper} main-attr>
            <ReturnBack></ReturnBack>
            <h1>Материально-техническое обеспечение и оснащенность образовательного процесса.</h1>
            <p>
                В ООО «ИТК» созданы условия для работы электронной информационно-образовательной среды, которая
                включает:
            </p>
            <ul>
                <li>
                    - электронные информационные ресурсы;
                </li>
                <li>
                    - электронные образовательные ресурсы;
                </li>
                <li>- набор необходимых информационных и телекоммуникационных технологий, а также технологических
                    средств.
                </li>
            </ul>
            <p>
                Указанная электронная образовательная среда позволяет обучающимся полностью осваивать образовательные
                программы независимо от местоположения обучающихся.
            </p><p>
            Дистанционное обучение осуществляется на собственных электронных образовательных ресурсах.
        </p><p>
            После заключения договора и подачи заявки на обучение обучающийся получает полный доступ к электронным
            материалам, включая текстовые материалы, презентации, видеолекции и методические пособия.
        </p><p>
            В ООО «ИТК» не предусмотрено проведение очных образовательных программ в помещениях; вся учебная
            деятельность осуществляется заочно с применением дистанционных образовательных технологий.
        </p><p>
            ООО «ИТК» гарантирует доступ для слушателей с ограниченными возможностями здоровья:
        </p>
            <ul>
                <li>
                    - информационным системам и телекоммуникационным сетям;
                </li>
                <li>
                    - электронным образовательным ресурсам.
                </li>
            </ul>


            {/*    В ООО «ИТК» созданы условия для работы электронной информационно-образовательной среды, которая включает:*/}
            {/*    - электронные информационные ресурсы;*/}
            {/*    - электронные образовательные ресурсы;*/}
            {/*    - набор необходимых информационных и телекоммуникационных технологий, а также технологических средств.*/}

            {/*    Указанная электронная образовательная среда позволяет обучающимся полностью осваивать образовательные программы независимо от местоположения обучающихся.*/}

            {/*    Дистанционное обучение осуществляется на собственных электронных образовательных ресурсах.*/}

            {/*    После заключения договора и подачи заявки на обучение обучающийся получает полный доступ к электронным материалам, включая текстовые материалы, презентации, видеолекции и методические пособия.*/}

            {/*    В ООО «ИТК» не предусмотрено проведение очных образовательных программ в помещениях; вся учебная деятельность осуществляется заочно с применением дистанционных образовательных технологий.*/}

            {/*    ООО «ИТК» гарантирует доступ для слушателей с ограниченными возможностями здоровья:*/}
            {/*    - информационным системам и телекоммуникационным сетям;*/}
            {/*    - электронным образовательным ресурсам.*/}


            {/*    <p>В ООО «ИТК» созданы материально-технические условия для успешного освоения программ*/}
            {/*        дополнительного профессионального образования.</p>*/}
            {/*    <h2>Об оборудованных учебных кабинетах:</h2>*/}
            {/*    <ul>*/}
            {/*        <li>Адрес (местоположение): г.Таганрог, пер.Тургеневский, 11, ком.1-7</li>*/}
            {/*        <li>Наименование: Учебный класс</li>*/}
            {/*        <li>Площадь помещения: 25,3 (стол преподавателя-1шт., стол обучающегося -5 шт., кресла компьютерные -*/}
            {/*            6шт., ноутбук с выходом в интернет- 6 шт., проектор-1шт.)*/}
            {/*        </li>*/}
            {/*        <li>Документ - основание возникновения права пользования: Договор аренды нежилого помещения №1 от*/}
            {/*            01.07.2023г*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*    <p>Объекты спорта ООО «ИТК» отсутствуют.</p>*/}
            {/*    <p>Питание учащихся отсутствует с связи с осуществлением деятельности только в сфере дополнительного*/}
            {/*        профессионального образования</p>*/}
            {/*    <p>В образовательной организации в учебных классах организован доступ к информационно-телекоммуникационной*/}
            {/*        сети Интернет и информационным системам</p>*/}
            {/*    <h2>Условия охраны здоровья обучающихся</h2>*/}
            {/*    <p>*/}
            {/*        Санитарные и гигиенические нормы соблюдаются, уровень обеспечения охраны здоровья слушателей и*/}
            {/*        работников соответствуют установленным требованиям.*/}
            {/*    </p>*/}
            {/*    <p>*/}
            {/*        В ООО «ИТК» размещены планы эвакуации людей, оборудования и других материальных ценностей на*/}
            {/*        случай пожара; установлены огнетушители; обеспечен контроль недопущения захламления помещений и путей*/}
            {/*        эвакуации посторонними предметами.*/}
            {/*    </p>*/}
            {/*    <h2>Охрана здоровья обучающихся включает:</h2>*/}
            {/*    <ol>*/}
            {/*        <li>Оказание первичной медико-санитарной помощи в порядке, установленном законодательством в сфере*/}
            {/*            охраны здоровья;*/}
            {/*        </li>*/}
            {/*        <li>Пропаганду и обучение навыкам здорового образа жизни, требованиям охраны труда;</li>*/}
            {/*        <li>Прохождение преподавателями в соответствии с законодательством Российской Федерации о периодических*/}
            {/*            медицинских осмотрах и диспансеризации;*/}
            {/*        </li>*/}
            {/*        <li>Профилактику и запрет курения, употребления алкогольных, слабоалкогольных напитков, пива,*/}
            {/*            наркотических средств и психотропных веществ;*/}
            {/*        </li>*/}
            {/*        <li>Обеспечение безопасности обучающихся во время пребывания в образовательной организации;</li>*/}
            {/*        <li>Профилактику несчастных случаев с обучающимися во время пребывания в образовательной организации;*/}
            {/*        </li>*/}
            {/*        <li>Проведение санитарно-противоэпидемических и профилактических мероприятий.</li>*/}
            {/*    </ol>*/}

            {/*    <h2>Материально-техническое обеспечение и оснащенность образовательного процесса. Доступная среда</h2>*/}
            {/*    <p>Для повышения эффективности обучения учебные группы комплектуются преимущественно из лиц одной или*/}
            {/*        родственных категорий обучаемых, с учетом уровня их подготовки. Количество слушателей в группе не должно*/}
            {/*        превышать 5 человек. Для лучшего усвоения материалов образовательных программ возможно индивидуальное*/}
            {/*        обучение слушателей.*/}
            {/*    </p><p>В наличии учебные кабинеты, оборудованные современными техническими средствами обучения, позволяющие*/}
            {/*    проводить занятия с применением дистанционных образовательных технологий и электронного обучения.*/}
            {/*</p><p>Оборудованные объекты для проведения практических занятий отсутствуют.*/}
            {/*</p><p>Учебная нагрузка и режим учебных занятий определяются ООО «ИТК» в соответствии с действующим*/}
            {/*    законодательством об образовании и о труде.*/}
            {/*</p><p>Режим занятий устанавливается в соответствии с расписанием.*/}
            {/*</p><p>Образование осуществляется на государственном языке Российской Федерации - русском.*/}
            {/*</p><p>Стипендии обучающимся не предоставляются. Общежития, интерната для проживания слушателей нет. Объектов*/}
            {/*    спорта на территории ООО «ИТК» нет.*/}
            {/*</p><p><b>Библиотека</b> ООО «ИТК» сформирована в электронном виде. Доступ к электронным образовательным*/}
            {/*    ресурсам предоставляется всем слушателям курсов на протяжении всего срока обучения.*/}
            {/*</p><p><b>К информационным системам и информационно-телекоммуникационным сетям</b> обеспечен доступ всех*/}
            {/*    обучающихся, в том числе инвалидов и лиц с ограниченными возможностями здоровья. На официальном сайте*/}
            {/*    организации, осуществляющей обучение, есть версия для слабовидящих.*/}
            {/*</p>*/}
            {/*    <p>Электронные образовательные ресурсы, к которым обеспечивается доступ обучающихся, доступны всем*/}
            {/*        слушателям курсов с официального сайта ООО «ИТК». Данные ресурсы являются собственностью ООО «ИТК».</p>*/}
            {/*    <h2> ОБЕСПЕЧЕНИЕ СРЕДСТВАМИ ОБУЧЕНИЯ И ВОСПИТАНИЯ, ЭЛЕКТРОННЫМИ ОБРАЗОВАТЕЛЬНЫМИ РЕСУРСАМИ</h2>*/}

            {/*    <p>ООО «ИТК» оснащена следующими средствами:</p>*/}
            {/*    <ol>*/}
            {/*        <li>*/}
            {/*            <b>Система дистанционного обучения,</b> которая предназначена для эффективного управления*/}
            {/*            дистанционным, очным и смешанным обучением. Система решает следующие задачи: регистрация*/}
            {/*            слушателей, преподавателей и администраторов, ведение их личных дел; формирование ролей и*/}
            {/*            распределение функций между пользователями; формирование учебных групп; создание учебных*/}
            {/*            курсов и программ обучения; подготовка упражнений и тестов, учебных материалов в различной*/}
            {/*            форме; учет результатов обучения в электронной ведомости и в личном деле; составление и*/}
            {/*            модификация расписания, синхронизированного по времени между участниками учебного процесса;*/}
            {/*            управление библиотекой учебных материалов; мониторинг и сбор статистики в процессе обучения;*/}
            {/*            анализ результатов учебной деятельности и подготовка различных видов отчетов; автоматизация*/}
            {/*            оценки знаний; обеспечение интерактивного общения: форумы, чаты, виртуальные классы,*/}
            {/*            тренинги, видеотрансляции, online семинары; функции проведения опросов различного вида.*/}
            {/*            Каждый пользователь портала имеет свой личный кабинет, в котором планируется и*/}
            {/*            осуществляется обучение, организуется доступ к учебным материалам, тестам, обучению,*/}
            {/*            коммуникациям и происходит управление процессами.*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            <b>Учебные электронные материалы (лекции)</b>*/}
            {/*            <div></div>*/}
            {/*            Для осуществления образовательного процесса используются учебные электронные материалы, с*/}
            {/*            помощью которых обучающиеся осваивают дополнительные профессиональные программы.*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            <b>Оценочные электронные материалы (тесты)</b>*/}
            {/*            <div></div>*/}
            {/*            Для оценки качества знаний обучающихся разработаны и применяются для проведения*/}
            {/*            промежуточной и итоговой аттестации оценочные электронные материалы в виде тестов.*/}

            {/*        </li>*/}
            {/*    </ol>*/}
            {/*    <p>Всем обучающимся на период обучения в организации предоставляется доступ к информационным*/}
            {/*        системам и информационно-телекоммуникационным сетям.</p>*/}


            {/*    <h2>Информация о специальных условиях для получения образования инвалидами и лицами с ограниченными*/}
            {/*        возможностями здоровья, в том числе:</h2>*/}


            {/*    <table border="1">*/}
            {/*        <thead>*/}
            {/*        <tr>*/}
            {/*            <th>№</th>*/}
            {/*            <th>Наименование условия</th>*/}
            {/*            <th>Наличие</th>*/}
            {/*        </tr>*/}
            {/*        </thead>*/}
            {/*        <tbody>*/}
            {/*        <tr>*/}
            {/*            <td>1</td>*/}
            {/*            <td>Специально оборудованные учебные кабинеты</td>*/}
            {/*            <td>Не имеется</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td>2</td>*/}
            {/*            <td>Объекты для проведения практических занятий, приспособленных для использования инвалидами</td>*/}
            {/*            <td>Не имеется</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td>3</td>*/}
            {/*            <td>Электронная библиотека, приспособленная для использования инвалидами</td>*/}
            {/*            <td><b>Имеется</b></td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td>4</td>*/}
            {/*            <td>Объекты спорта, приспособленные для использования инвалидами</td>*/}
            {/*            <td>Не имеется</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td>5</td>*/}
            {/*            <td>Средства обучения и воспитания, приспособленные для использования инвалидами (электронные*/}
            {/*                ресурсы)*/}
            {/*            </td>*/}
            {/*            <td><b>Имеется</b></td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td>6</td>*/}
            {/*            <td>Обеспечение доступа в здания образовательной организации</td>*/}
            {/*            <td>Не имеется</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td>7</td>*/}
            {/*            <td>Специальные условия питания</td>*/}
            {/*            <td>Не имеется</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td>8</td>*/}
            {/*            <td>Специальные условия охраны здоровья</td>*/}
            {/*            <td>Не имеется</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td>9</td>*/}
            {/*            <td>Доступ к информационным системам и сетям, приспособленным для использования инвалидами</td>*/}
            {/*            <td><b>Имеется</b></td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td>10</td>*/}
            {/*            <td>Электронные образовательные ресурсы, приспособленные</td>*/}
            {/*            <td><b>Имеется</b></td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td>11</td>*/}
            {/*            <td>Наличие специальных технических средств обучения коллективного и индивидуального*/}
            {/*                пользования*/}
            {/*            </td>*/}
            {/*            <td>Не имеется</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td>12</td>*/}
            {/*            <td>Наличие условий для беспрепятственного доступа в общежитие, интернат</td>*/}
            {/*            <td>Не имеется</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td>13</td>*/}
            {/*            <td>Количество жилых помещений в общежитии, интернате, приспособленных для использования*/}
            {/*                инвалидами*/}
            {/*                и лицами с ограниченными возможностями здоровья*/}
            {/*            </td>*/}
            {/*            <td>-</td>*/}
            {/*        </tr>*/}
            {/*        </tbody>*/}
            {/*    </table>*/}
        </main>
    );
};

export default Success;
